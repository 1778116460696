<template>
    <div class="mt-4">
        <b-row>
            <b-col v-if="contractsCanAccess">
                <b-card-group>
                    <b-card
                        title="System SOWA 2.0"
                        :img-src="contractsLogo"
                        img-alt="SOWA 2.0"
                        img-top
                        @click="redirect(contractsUrl)"
                    />
                </b-card-group>
            </b-col>
            <b-col v-if="czajkaCanAccess">
                <b-card-group>
                    <b-card
                        title="System Czajka 2.0"
                        :img-src="czajkaLogo"
                        img-alt="CZAJKA 2.0"
                        img-top
                        @click="redirect(czajkaUrl)"
                    />
                </b-card-group>
            </b-col>
            <b-col></b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props:{
        contractsLogo: {type: String},
        contractsUrl: {type: String},
        contractsCanAccess: {type: Boolean, default: false},
        czajkaLogo: {type: String},
        czajkaUrl: {type: String},
        czajkaCanAccess: {type: Boolean, default: false},
    },
    methods: {
        redirect(url) {
            window.location.href = url;
        }
    }
}
</script>

<style lang="scss" scoped>
*::v-deep .card {
    max-width: calc(90% - 30px);
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}
*::v-deep .card .card-img-top {
    width: 50%;
}
*::v-deep .card .card-body {
    padding: 0;
}
*::v-deep .card .card-body h4 {
    font-size: 28px;
    margin-top: 12px;
    font-weight: 700;
    margin-bottom: 0;
    color: #002F6D;
}
</style>
