<template>
    <div>
        <table-grid-filter :with-search="true" :with-apply-clear-filters="false">
            <template #search>
                <b-form-input v-model="filter.search" class="input-search" placeholder="Imię, Nazwisko" @keyup.enter="refreshData()"></b-form-input>
            </template>
        </table-grid-filter>
        <div class="table-margin">
            <table-grid :data="dataList">
                <template #actions="{ item }">
                    <UserActions :item="item.item" @handleAction="handleAction"></UserActions>
                </template>
            </table-grid>
        </div>
        <UserModal
            :itemData="itemData"
            :roles="users_roles"
            @dataChanged="refreshData"
            :modal="modal"
            :endpoint="endpoint"
        />
    </div>
</template>

<script>
import UserModal from "../modals/UserModal.vue";
import UserActions from "../UserActions.vue";

export default {
    name: "UserTab",
    props: {
        users_roles: {type: Array},
        refresh: {type: Boolean, default: false}
    },
    components:{
        UserModal, UserActions
    },
    data(){
        return {
            dataList: [],
            itemData: {},
            filter: {
                search: null
            },
            endpoint: 'api.admin.user.',
            modal: 'modal-user'
        }
    },
    methods: {
        destroyItem(item){
            this.onDestroyItem(this.endpoint, {group: item.id});
        },
        editItem(item){
            this.itemData = item;
            this.$bvModal.show(this.modal);
        },
        handleAction(event){
            this[event.action](event.item)
        },
    },
    mounted(){
        this.refreshData();
    },
    watch:{
        active_tab: function() {
            this.refreshData()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>


