<template>
    <div class="mr-2">
        <b-form-select
            v-model="selected"
            :select-size="size"
            :required="required"
            :class="selectClass"
            :disabled="disabled"
        >
            <template #first>
                <b-form-select-option :value="null" disabled>Dział</b-form-select-option>
            </template>
            <option :selected="selected === division.id" v-for="division in divisionsList" :value="division.id">
                {{ division.name }}
            </option>
        </b-form-select>
    </div>
</template>

<script>
export default {
    name: "MainDivisionSelect",
    props: {
        divisions: {type: Array, default: ()=>[]},
        selectedItem: {default: null},
        required: {type: Boolean, default: false},
        multiple: {type: Boolean, default: false},
        size: {type: Number, default: 1},
        placeholder: {type: String, default: ''},
        clear: {type: Boolean, default:false},
        selectClass: {type: String, default: ''},
        limited: false,
        limitedList: {type: [Array, Object], default: () => []},
        disabled: {type: Boolean, default: false},
    },
    data() {
        return {
            display: 'none',
            selected: this.selectedItem,
            statuses: [],
        }
    },
    computed: {
        divisionsList() {
            if (this.limited) {
                let division_list = this.divisions.filter(
                    (type) => this.limitedList.includes(type.id)
                );
                this.allDivisions = division_list;

                return this.allDivisions;
            }
        }
    },
    watch: {
        selected() {
            this.$emit('divisionMainChanged', this.selected);
        },
        clear() {
            if (this.clear){
                this.selected = null
            }
        }
    }
}
</script>

